import { useGlobal } from "@/components/Global";
import Cookie from "@/utils/Cookie";
import { createAccountBySign } from "@/api/global";
import fetch from "@/utils/fetch";
import aes from "@/utils/AES";

export const getUserInfo = () =>
  fetch("/c-usercenter-consumer/user/getUserInfo", {
    needCode: true,
  });

const useUser = () => {
  const [user, setUser] = useGlobal(["user"]);

  const login = async (mobile, code) => {
    setUser({ loading: true });
    const data = await createAccountBySign({
      mobile: aes.encrypt(mobile, aes.MOBILE_KEY),
      code,
      shareRecId: window?.tcc?.globalQuery?.shareRecId,
    });
    if (data?.token) {
      Cookie.set("token", data?.token, { expires: 30 });
      Cookie.set("TaoCheUserIdentity", data?.token, { expires: 30 });
      Cookie.set("LoanUserId", data?.unifiedNumberStr, { expires: 30 });
      const userRes = await getUserInfo(true);
      if (userRes?.code === 0) {
        const u = { loading: false, loaded: true, ...userRes?.data };
        setUser(u);
        return u;
      }
    }
    setUser({ loading: false, islogin: false, loaded: true });
    return { loading: false, islogin: false, loaded: true };
  };

  const logout = () => {
    Cookie.remove("token");
    Cookie.remove("LoanUserId");
    Cookie.remove("TaoCheUserIdentity");
    setUser({ islogin: false, loading: false, loaded: false });
  };

  return { login, logout, user };
};

export default useUser;
