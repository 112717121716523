import fetch from "@/utils/fetch";
import aes from "@/utils/AES";

//http://wiki.taocheche.com/pages/viewpage.action?pageId=15534152
// 留资
export const saveClue = (body) =>
  fetch("/c-clue-consumer/clue/common-save-lead", {
    method: "post",
    body,
    AESKEY: aes.MOBILE_KEY,
  });

// 预约到店
export const reservation = (body) =>
  fetch("/c-clue-consumer/clue/reservation", {
    method: "post",
    body,
    AESKEY: aes.MOBILE_KEY,
  });

// 校验 验证码
export const validateMobileCode = (body) =>
  fetch(`/c-usercenter-consumer/user/validateMobileCode`, {
    method: "post",
    body,
    needCode: true,
  });

// 登录
export const createAccountBySign = (body) =>
  fetch(`/c-usercenter-consumer/user/createAccountBySign`, {
    body,
    method: "post",
  });
