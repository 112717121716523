/**
 *  @Author: qp
 *  @Date: 2023-11-08 11:22:15
 * @Last Modified by: qp
 * @Last Modified time: 2024-07-18 14:01:32
 *  @name 公共请求
 **/

import qs from "qs";
import { includes, dissoc, mergeDeepRight } from "ramda";
import Cookies from "@/utils/Cookie";
import moment from "moment";
import aes from "./AES";

export let host =
  typeof window !== "undefined"
    ? "https://proconsumer.taocheche.com"
    : "http://inter-proconsumer.taocheche.com";

export let h5_host = "https://static.taocheche.com/taoche-h5";
export let self_host = "https://m.taocheche.com";

if (process.env.RUNTIME_ENV === "test") {
  host = "https://consumeruat.taocheche.com";
  h5_host = "http://uat-static.taocheche.com/taoche-h5";
  self_host = "//test.m.taocheche.com";
} else if (process.env.RUNTIME_ENV === "beta") {
  h5_host = "https://static.taocheche.com/taoche-h5huidu";
  host = "https://consumerhuidu.taocheche.com";
  self_host = "//beta.m.taocheche.com";
}
export let app_host = `${self_host}/apps`;

function _fetch(url, options) {
  let controller = null;
  let timeout = null;
  let _options = {
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",

    ...options,
  };

  if (typeof window !== "undefined") {
    try {
      let storeId = Cookies.get("storeId");
      const city = JSON.parse(Cookies.get("city") || "{}");
      const cityId = city?.cityId;
      const locationCityId = JSON.parse(
        Cookies.get("locationCity") || "null"
      )?.cityId; //经纬度定位城市id
      if (city?.storeId) storeId = city?.storeId;
      const token = Cookies.get("token");
      const lng = Cookies.get("longitude"); // 经度
      const lat = Cookies.get("latitude"); // 纬度

      if (cityId) _options.headers.cityId = cityId;
      locationCityId
        ? (_options.headers["x-locationCityId"] = locationCityId)
        : null; //经纬度定位城市id
      if (storeId) _options.headers.storeId = storeId;
      if (token) {
        _options.headers.Authorization = `Bearer ${token}`;
      }

      lng
        ? (_options.headers["x-longitude"] = aes.encrypt(lng, aes.MOBILE_KEY))
        : null; // 经度
      lat
        ? (_options.headers["x-latitude"] = aes.encrypt(lat, aes.MOBILE_KEY))
        : null; // 纬度
    } catch (err) {
      console.info(err);
    }
  } else {
    const { headers, cookies } = require("next/headers");
    // const { revalidateTag } = require("next/cache");
    const ua = headers().get("user-agent");

    controller = new AbortController();
    timeout = setTimeout(() => {
      controller.abort();
    }, 500);

    _options.headers["user-agent"] = ua;
    _options.headers["cookie"] = headers().get("cookie");
    _options.headers["deviceId"] = headers().get("deviceId");
    _options.headers["origin"] = headers().get("origin");
    _options.headers["referer"] = headers().get("referer");
    _options.headers["x-forwarded-for"] = headers().get("x-forwarded-for");
    _options.headers["signal"] = controller.signal; // 将 signal 选项传递给 fetch 请求
    try {
      if (cookies().has("city")) {
        const city = JSON.parse(cookies().get("city").value);
        if (city?.storeId) {
          _options.headers["storeId"] = city?.storeId;
        }
        if (city?.cityId) {
          _options.headers["cityId"] = city?.cityId;
        }
      }
    } catch {
      console.error("格式转换错误，fetch方法，storeId");
    }

    if (cookies().has("longitude") && cookies().has("latitude")) {
      _options.headers["longitude"] = aes.encrypt(
        cookies().get("longitude").value,
        aes.MOBILE_KEY
      );
      _options.headers["latitude"] = aes.encrypt(
        cookies().get("latitude").value,
        aes.MOBILE_KEY
      );
    }

    const token = cookies().has("token") ? cookies().get("token")?.value : null;
    let terminalType = 20; // m
    // revalidateTag(token);
    if (ua.includes("Windows") || ua.includes("Macintosh")) {
      terminalType = 10; //pc
    }
    _options.headers["terminalType"] = terminalType;
    if (token) {
      _options.headers["Authorization"] = `Bearer ${token}`;
    }
  }
  let _url = host + url;

  // 处理post请求 body 参数
  if (_options.body) {
    _options.body = JSON.stringify(_options.body);
    let timestamp = new Date().getTime();
    _options.headers.timestamp = timestamp;
    _options.headers.sign = aes.encrypt(
      _options.body + timestamp + (options?.AESKEY || aes._KEY),
      options?.AESKEY
    );
  }
  // 处理get请求 query string 参数
  if (_options.params) {
    if (includes("?", _url)) {
      _url = `${_url}&${qs.stringify(_options.params)}`;
    } else {
      _url = `${_url}?${qs.stringify(_options.params)}`;
    }
    _options = dissoc("params", _options);
  }
  // const _optionsReal = interceptorsFn.reduce(
  //   (p, fn) => mergeDeepRight(p, fn(p)),
  //   _options
  // );
  const _optionsReal = _options;
  const startFetchingTime = new Date().getTime();
  return fetch(_url, _optionsReal)
    .then((res) => {
      clearTimeout(timeout);
      if (typeof window === "undefined") {
        const n = new Date().getTime();
        console.log(
          `${_url}   时间:${moment(n).format("YYYY-MM-DD HH:mm:ss")};耗时:${
            n - startFetchingTime
          }ms`
        );
      }
      return res.json();
    })
    .then((res) => {
      if (!_optionsReal.needCode) {
        if (res.code !== 0 && res.code !== 100) {
          if (typeof window !== "undefined") {
            const { Toast } = require("antd-mobile");
            Toast.show({
              content: res?.message || "接口请求异常",
            });
          }
          console.info("接口请求异常", _url, res);
          throw res?.message;
        }
        return res?.data;
      }
      return res;
    })
    .catch((err) => {
      clearTimeout(timeout);
      if (err.name === "AbortError") {
        console.log("请求超时", _url);
      } else {
        console.error("服务请求异常:", err, _url, _optionsReal);
      }
    });
}

export default _fetch;
