/* eslint-disable no-redeclare */
/* eslint-disable no-var */
import CryptoJS from "crypto-js"; // 加密组件

const AES = {
  MOBILE_KEY: "14ba97e95f8a6b3a1397887e9a84d289", // 32位
  _KEY: "di8asf5as1kf8i94uas8fl7as2dfj6k7",
  _IV: "//www.taoche.com", // 16位

  encrypt(str, key, iv) {
    var key = CryptoJS.enc.Utf8.parse(key || this._KEY);
    var iv = CryptoJS.enc.Utf8.parse(iv || this._IV);

    let encrypted = "";

    const srcs = CryptoJS.enc.Utf8.parse(str);
    encrypted = CryptoJS.AES.encrypt(srcs, key, {
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return encrypted.ciphertext.toString();
  },

  decrypt(str, key, iv) {
    var key = CryptoJS.enc.Utf8.parse(key || this._KEY);
    var iv = CryptoJS.enc.Utf8.parse(iv || this._IV);
    const encryptedHexStr = CryptoJS.enc.Hex.parse(str);
    const srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
    const decrypt = CryptoJS.AES.decrypt(srcs, key, {
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr.toString();
  },
};

export default AES;
