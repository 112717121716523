"use client";
import { useEffect } from 'react'
import { Toast } from 'antd-mobile'
import useMemoizedFn from './useMemoizedFn'

export default function useBackListen() {
    const handleBack = useMemoizedFn(() => {
        Toast.clear();
    });

    useEffect(() => {
        window.addEventListener("popstate", handleBack);
        return () => {
            window.removeEventListener("popstate", handleBack);
        }
    }, [])

    return null
}
