import Cookie from "js-cookie";

const CookieSet = Cookie.set;
const CookieRemove = Cookie.remove;

const domain = process?.env?.NODE_ENV === "development" ? "" : ".taocheche.com";

Cookie.set = (key, value, options) => {
  CookieSet(key, value, { domain, ...options });
};

Cookie.remove = (key, options) => {
  CookieRemove(key, { domain, ...options });
};

export default Cookie;
